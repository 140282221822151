
.page{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    margin-top: 75px;
}

.title{
    width: 100%;
    padding: 25px;
    margin-bottom: 50px;
    text-align: center;
}

.subtitle{
    text-align: justify;
    width: 90%;
}

.about{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    border-radius: 15px;
    text-align: justify;
    margin-bottom: 50px;
}

.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:center;
    text-align: justify;
    width: 90%;
}

.item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
}

.headings > h5 {
    overflow: hidden;
  }
.headings > h6 {
    margin-top: -5px;
}
.headings > p {
    margin-top: -5px;
  }

.profile-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 15px;
    justify-content: space-between;
    text-align: justify;
    background-color:#f1f5f9;
    margin-top: 75px;
}

.profile-image{
  width: 50%;
  border-radius: 15px ;
}
.profile-content{
  width: 50%;
  margin: 3%;
  flex: 1; 
  aspect-ratio: 1;
  overflow: scroll;
}

.profile-card-content{
    width: 50%;
    margin: 3%;
    overflow: scroll;
    flex: 1; 
    aspect-ratio: 1;;
}

.title{
    background-color: #f1f5f9;
}



@media (max-width: 1100px) {
    .profile-card-content{
      width: 90%;
    }
    .profile-card{
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        flex-direction: column-reverse;
        background-color: #fff;
    }
    .profile-card#home-middle-div{
        flex-direction: column;
        background-color: #fff;
    }
    .profile-image{
        border-radius: 5%;
    }
}




/* Light mode (default) */
:root {
    --background-color: #fff;
    --elements-background-color: #f1f5f9;
    --text-color: #000;
    --heading-color: #1e293b; /* Color for headings */
    --navbar-text-color:#727272;
  }
  
  /* Dark mode */
  [data-theme="dark"] {
    --background-color: #0F172A;
    --elements-background-color: #1E293B;
    --text-color: #D7DADC; /* Light text for dark background */
    --heading-color: #E2E8F0; /* Light color for headings in dark mode */
    --navbar-text-color:#E2E8F0;
  }
  
  
/* Apply text colors globally */
body {
    background-color: var(--background-color); /* Set body background */
  color: var(--text-color); /* Set default text color */
}


.footer{
    background-color: var(--background-color);
  color: var(--text-color);
}
  
  h1, h2, h3, h4, h5, h6 {
    color: var(--heading-color);
  }
  
  p, span, a, li {
    color: var(--text-color);
  }
  .title {
    background-color: var(--elements-background-color);
    color: var(--text-color);
  }
  
  .profile-card{
    background-color: var(--elements-background-color);
    color: var(--text-color);
  }
  
  @media (max-width: 1100px) {
    .profile-card{
      background-color: var(--card-background-color);
      margin-top: 75px;
    }
    .profile-content{
      width: 100%;
      aspect-ratio:unset;
    }
    .profile-card-content{
      width: 100%;
      aspect-ratio:unset;
    }
    .profile-card#home-middle-div {
      background-color: var(--card-background-color);
      margin-top: 25px;
    }
    .profile-card#home-last-div {
      margin-top: 25px;
    }
  }