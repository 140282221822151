/* Navbar styles */
.navbar {
    position: fixed;
    z-index:1; 
    background-color: var(--navbar-background);
    color: var(--text-color);
  }

.navbar-offcanvas {
background-color: var(--elements-background-color);
color: var(--text-color);
}



/* Navbar.Brand styles */
.navbar-light-brand, .navbar-dark-brand {
    color: var(--navbar-text-color);
  }
  
  

  .navbar-light, .navbar-dark {
    background-color: var(--navbar-background);
    color: var(--text-color);
  }
  
  
/* Nav.Link styles */
.navbar .navbar-nav .nav-link{
    color: var(--navbar-text-color);
}
.navbar .navbar-nav .nav-link:hover{
    color: var(--text-color);
}
.navbar-brand{
    color: var(--navbar-text-color);
}
.navbar-brand:hover{
    color: var(--text-color);
}
