.contact-form{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-top: 75px;
}
.contact-title{
    background-color: #f1f5f9;
    width: 100%;
    padding: 25px;
    margin-bottom: 50px;
    text-align: center;
}
.contact-content{
    width: 70%;
    text-align: center;
    margin-bottom: 50px;
    text-decoration: none;
}